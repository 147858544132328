<template>
  <div>
    <!-- <img
      style="width: 100%; display: block"
      src="https://static.5t1800.com/s3/2023/05/09/6459a71b7581f.jpg"
      alt=""
    /> -->
    <video src="https://static.5t1800.com/s4/2023/8/29/af49aece7a33a7d29d1a19afbb229f6a.mp4" controls="controls" autoplay muted   style="width: 100%; display: block">
您的浏览器不支持 video 标签。
  </video>
    <!-- 适用人群 -->
    <div class="hidden-xs-only">
      <div class="skinTitle">适用人群</div>
      <ul class="crowdImg">
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab2f9e7d3.png"
            alt=""
          />
          <div>淡化各种斑</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab319a6ad.png"
            alt=""
          />
          <div>整体改善肤色</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab33c39b3.png"
            alt=""
          />
          <div>淡化痘印、疤痕</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab357b374.png"
            alt=""
          />
          <div>晒后修复</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab37462d7.png"
            alt=""
          />
          <div>医美术后保持</div>
        </li>
      </ul>
      <div class="crowdTxt">
        皮肤色斑和暗沉形成的原理主要与黑色素的代谢和沉积有关。当皮肤接触到紫外线、污染物、压力、疲劳等刺激时，黑色素细胞会大量产生黑色素，以抵御外界侵害。但如果黑色素代谢出现异常，黑色素在皮肤中沉积过多，就会形成色斑和暗沉。<br />
        聚光焕亮MTS皮肤管理项目从肌肤色沉的机理入手，选择谷胱甘肽、α熊果苷、烟酰胺、传明酸、光裂合酶等有效抑黑和修复成分研制作独家高效配方，再结合促渗仪、纳晶仪等科技仪器，提高产品吸收率，实现全链路断黑，且安全可控、无尴尬期
        。实际测试结果达到实现了护理一次即见效果，定期护理肌肤光彩照人。<br />
        本项目属于安全护理项目，非医美手术类，对肌肤没有损伤，适用场景广泛。任何希望淡斑美白、改善肤色的用户都可放心使用。
      </div>
    </div>
    <!-- 适用人群 H5 -->
    <div class="hidden-sm-and-up">
      <div class="skinTitlePhone">适用人群</div>
      <ul class="crowdImgPhone">
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab2f9e7d3.png"
            alt=""
          />
          <div>淡化各种斑</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab319a6ad.png"
            alt=""
          />
          <div>整体改善肤色</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab33c39b3.png"
            alt=""
          />
          <div>淡化痘印、疤痕</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab357b374.png"
            alt=""
          />
          <div>晒后修复</div>
        </li>
        <li>
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459ab37462d7.png"
            alt=""
          />
          <div>医美术后保持</div>
        </li>
      </ul>
      <div class="crowdTxtPhone">
        皮肤色斑和暗沉形成的原理主要与黑色素的代谢和沉积有关。当皮肤接触到紫外线、污染物、压力、疲劳等刺激时，黑色素细胞会大量产生黑色素，以抵御外界侵害。但如果黑色素代谢出现异常，黑色素在皮肤中沉积过多，就会形成色斑和暗沉。<br />
        聚光焕亮MTS皮肤管理项目从肌肤色沉的机理入手，选择谷胱甘肽、α熊果苷、烟酰胺、传明酸、光裂合酶等有效抑黑和修复成分研制作独家高效配方，再结合促渗仪、纳晶仪等科技仪器，提高产品吸收率，实现全链路断黑，且安全可控、无尴尬期
        。实际测试结果达到实现了护理一次即见效果，定期护理肌肤光彩照人。<br />
        本项目属于安全护理项目，非医美手术类，对肌肤没有损伤，适用场景广泛。任何希望淡斑美白、改善肤色的用户都可放心使用。
      </div>
    </div>

    <!-- 项目特点 -->
    <div class="hidden-xs-only">
      <div class="skinTitle">项目特点</div>
      <div class="feature">
        <div class="featureImg">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afe8b9d53.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afeb679ff.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afed77c4a.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afef88bae.png"
            alt=""
          />
        </div>
        <div class="featureTitle">>>> 六步开启透光焕亮</div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b1df3714e.png"
          alt=""
        />
        <div class="featureTitle">>>> 专利成分</div>
        <ul class="patent">
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b08edb4.png"
              alt=""
            />
            <div>
              专利成分：光裂合酶<br />
              专利号：ZL202110103205.2
            </div>
          </li>
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b319db7.png"
              alt=""
            />
            <div>
              专利成分：重组人III型胶原蛋白<br />
              专利号：ZL202110103205.2
            </div>
          </li>
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b46c5f6.png"
              alt=""
            />
            <div>
              无防腐体系配方<br />
              进口米糠水解液有机认证
            </div>
          </li>
        </ul>
        <div class="featureTitle">>>> 仪器加持</div>
        <div class="instrument flex a_c">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b4c5297a0.png"
            alt=""
          />
          <div>
            <span>氢氧注渗笔 </span><br />
            首创纳米离子热，提升面部微循环，帮助肌肤升温，有助于后续营养吸收，同时保护细胞促进胶原蛋白合成。<br />
            <span>纳米促渗笔 </span><br />
            利用双通道分子合流技术，通过强压氧产生喷雾粒子流，细腻雾化，促进精华珍稀成分穿透肌肤裂纹毛孔层层渗透，为肌肤带来透皮水光SPA。<br />
            <span>冰肌锤 </span><br />
            运用冷冻舒缓科技，特殊晶片陶瓷吸收能量，环绕全脸镇静，减少护理中肌肤可能出现的不适感和刺激感，达到收细毛孔、舒缓修复的功能。<br />
            <span>MTS纳晶笔 </span><br />
            利用纳米级硅晶片高频导入，高效率，强吸收，让专研精华液营养成分由内而外轻松抵达底层，显著焕白肌肤。<br />
          </div>
        </div>
        <div class="featureTitle">>>> 效果验证</div>
        <div class="effectTxt">
          无论产品中所用成分的实验室测定效果，或是用户实际使用过程中的直观感受，均证明了薇妮聚光焕亮项目在淡斑提亮方面的显著功效。<br />
          通过对大量用户使用过程进行档案分析，针对皮肤暗黄、晒斑、雀斑的情况，连续使用薇妮聚光焕亮项目，效果均达到或超出了用户的预期，皮肤状态改善明显。
        </div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b6841dbe1.png"
          alt=""
        />
        <div class="effectImg flex a_c j_s">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71d218de.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b7148ef96.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71744d82.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71ae4945.png"
            alt=""
          />
        </div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b6e5d6e93.png"
          alt=""
        />
      </div>
    </div>
    <!-- 项目特点 H5 -->
    <div class="hidden-sm-and-up">
      <div class="skinTitlePhone">项目特点</div>
      <div class="featurePhone">
        <div class="featureImg">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afe8b9d53.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afeb679ff.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afed77c4a.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459afef88bae.png"
            alt=""
          />
        </div>
        <div class="featureTitle">>>> 六步开启透光焕亮</div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b1df3714e.png"
          alt=""
        />
        <div class="featureTitle">>>> 专利成分</div>
        <ul class="patent">
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b08edb4.png"
              alt=""
            />
            <div>
              专利成分：光裂合酶<br />
              专利号：ZL202110103205.2
            </div>
          </li>
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b319db7.png"
              alt=""
            />
            <div>
              专利成分：重组人III型胶原蛋白<br />
              专利号：ZL202110103205.2
            </div>
          </li>
          <li>
            <img
              src="https://static.5t1800.com/s3/2023/05/09/6459b2b46c5f6.png"
              alt=""
            />
            <div>
              无防腐体系配方<br />
              进口米糠水解液有机认证
            </div>
          </li>
        </ul>
        <div class="featureTitle">>>> 仪器加持</div>
        <div class="instrument">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b4c5297a0.png"
            alt=""
          />
          <div>
            <span>氢氧注渗笔 </span><br />
            首创纳米离子热，提升面部微循环，帮助肌肤升温，有助于后续营养吸收，同时保护细胞促进胶原蛋白合成。<br />
            <span>纳米促渗笔 </span><br />
            利用双通道分子合流技术，通过强压氧产生喷雾粒子流，细腻雾化，促进精华珍稀成分穿透肌肤裂纹毛孔层层渗透，为肌肤带来透皮水光SPA。<br />
            <span>冰肌锤 </span><br />
            运用冷冻舒缓科技，特殊晶片陶瓷吸收能量，环绕全脸镇静，减少护理中肌肤可能出现的不适感和刺激感，达到收细毛孔、舒缓修复的功能。<br />
            <span>MTS纳晶笔 </span><br />
            利用纳米级硅晶片高频导入，高效率，强吸收，让专研精华液营养成分由内而外轻松抵达底层，显著焕白肌肤。<br />
          </div>
        </div>
        <div class="featureTitle">>>> 效果验证</div>
        <div class="effectTxt">
          无论产品中所用成分的实验室测定效果，或是用户实际使用过程中的直观感受，均证明了薇妮聚光焕亮项目在淡斑提亮方面的显著功效。<br />
          通过对大量用户使用过程进行档案分析，针对皮肤暗黄、晒斑、雀斑的情况，连续使用薇妮聚光焕亮项目，效果均达到或超出了用户的预期，皮肤状态改善明显。
        </div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b6841dbe1.png"
          alt=""
        />
        <div class="effectImg flex a_c j_s">
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71d218de.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b7148ef96.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71744d82.png"
            alt=""
          />
          <img
            src="https://static.5t1800.com/s3/2023/05/09/6459b71ae4945.png"
            alt=""
          />
        </div>
        <img
          style="width: 100%"
          src="https://static.5t1800.com/s3/2023/05/09/6459b6e5d6e93.png"
          alt=""
        />
      </div>
    </div>

    <img
      style="width: 100%; margin-top: 20px; display: block"
      src="https://static.5t1800.com/s3/2023/05/09/6459b7d511955.jpg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  created() {
    this.$store.commit("saveTypeIndex", 2);
  },
  methods: {},
};
</script>

<style scoped>
.body {
  width: 1000px;
  margin: 50px auto;
}
.skinTitle {
  height: 70px;
  background: #eeeeee;
  font-size: 35px;
  font-weight: bold;
  color: #957cf2;
  text-align: center;
  line-height: 70px;
}
.skinTitlePhone {
  height: 50px;
  background: #eeeeee;
  font-size: 24px;
  font-weight: bold;
  color: #957cf2;
  text-align: center;
  line-height: 50px;
}
.crowdImg {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  color: #acacac;
  text-align: center;
}
.crowdImg img {
  width: 230px;
  height: 320px;
}

.crowdImgPhone {
  box-sizing: border-box;
  padding: 30px 20px;
  font-size: 20px;
  font-weight: 400;
  color: #acacac;
  text-align: center;
}
.crowdImgPhone li {
  margin-bottom: 10px;
}
.crowdImgPhone img {
  width: 100%;
}

.crowdTxt {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 300;
  color: #a0a0a0;
  line-height: 48px;
}

.crowdTxtPhone {
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 20px;
  font-weight: 300;
  color: #a0a0a0;
  line-height: 38px;
}
.feature {
  max-width: 1200px;
  margin: 0 auto;
}
.feature .featureImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}
.feature .featureImg img {
  width: 582px;
  height: 317px;
  display: block;
  margin-bottom: 20px;
}
.feature .featureTitle {
  font-size: 35px;
  font-weight: 400;
  color: #957cf2;
  height: 100px;
  line-height: 100px;
}
.feature .patent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.feature .patent img {
  width: 364px;
  height: 515px;
  display: block;
}
.feature .patent div {
  font-size: 20px;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 27px;
  padding: 10px 0;
}
.feature .instrument img {
  width: 290px;
  height: 456px;
  margin-right: 10px;
}
.feature .instrument div {
  font-size: 24px;

  color: #a0a0a0;
  line-height: 42px;
}
.feature .instrument div span {
  color: #7a7a7a;
  font-weight: bold;
}
.feature .effectTxt {
  font-size: 24px;
  font-weight: 300;
  color: #a0a0a0;
  line-height: 48px;
}
.feature .effectImg {
  flex-wrap: wrap;
}
.feature .effectImg img {
  width: 590px;
  height: 213px;
  margin-bottom: 10px;
}





.featurePhone {
  padding:0 12px;
}
.featurePhone .featureImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}
.featurePhone .featureImg img {
  width:100%;
  display: block;
  margin-bottom: 20px;
}
.featurePhone .featureTitle {
  font-size: 24px;
  font-weight: 400;
  color: #957cf2;
  height: 60px;
  line-height: 60px;
}
.featurePhone .patent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.featurePhone .patent img {
  width:100%;
  display: block;
}
.featurePhone .patent div {
  font-size: 20px;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 27px;
  padding: 10px 0;
  margin-bottom: 10px;

}
.featurePhone .instrument img {
  width:60%;
  display: block;
  margin:0 auto;
}
.featurePhone .instrument div {
  font-size: 18px;

  color: #a0a0a0;
  line-height: 36px;
}
.featurePhone .instrument div span {
  color: #7a7a7a;
  font-weight: bold;
}
.featurePhone .effectTxt {
   font-size: 20px;
  font-weight: 300;
  color: #a0a0a0;
  line-height: 38px;
}
.featurePhone .effectImg {
  flex-wrap: wrap;
}
.featurePhone .effectImg img {
 width:100%;
  margin-bottom: 10px;
}
</style>

